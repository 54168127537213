import React from "react";
import Filter from "../../components/crm/Filter";
import LeadsTable from "../../components/crm/LeadsTable";
//css
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CrmModal from "./CrmModal";
// import CrmEditLead from './CrmEditLead';
import { AddonNotification, AlertMessage } from "../common";
import AddForm from "./AddForm";
const CrmViewLead = (props) => {
  const { t } = useTranslation();
  return (
    <Container fluid className={`p-3`}>
      {/* <AddonNotification/> */}
      <Filter
        {...props}
        title={t("Crm.findLead")}
        country={props.country.country}
        chnageHandler={props.chnageHandler}
        datechange={props.datechange}
        onSubmitForm={props.onSubmitForm}
      />
      <LeadsTable
        leads={props.data.leads}
        handleClose={props.handleClose}
        showModal={props.showModal}
      />
      <CrmModal
        show={props.modal.show}
        handleClose={props.handleClose}
        title={t("Crm.editLead")}
      >
        {props.modal.title === "edit" && (
          <AddForm
            data={props.modal.data}
            id={props.modal.id}
            BtnName="updateLead"
            isUpdate={true}
            submitCallback={props.submitCallback}
            LeadData={props.LeadData}
          />
        )}
      </CrmModal>
      {props.notify.show && (
        <AlertMessage
          message={props.notify.message}
          dismiss={props.notifyDissmissed}
          type={props.notify.type}
          header={props.notify.header}
          show={props.notify.show}
        />
      )}
    </Container>
  );
};

export default CrmViewLead;
