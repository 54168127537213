// // local
// import companyName from "../store/mobxStore/companyName";
// const COMPANY_NAME = companyName.companyName ?? "Company Name";
// const BASE_URL = "http://192.168.12.9:5002/api/web/";
// const IMG_URL = "http://192.168.12.9:5002/api/uploads/images/";
// const LEAD_URL = "http://192.168.12.9:5002/api/web/";
// const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// // const DEFAULT_KEY ='903'
// const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
// const BASE = "http://192.168.12.9:5002/";

// http://184.168.125.186:5000/

//demo

// import companyName from "../store/mobxStore/companyName";
// const COMPANY_NAME = companyName.companyName ?? "Company Name";
// const BASE_URL = "http://162.19.146.134:1248/api/web/";
// const IMG_URL = "http://162.19.146.134:1248/api/uploads/images/";
// const LEAD_URL = "http://162.19.146.134:1248/api/web/";
// const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// // const DEFAULT_KEY ='903'
// const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
// const BASE = "http://162.19.146.134:1248/";

//Live

// import companyName from "../store/mobxStore/companyName";
// const COMPANY_NAME = companyName.companyName ?? "Company Name";
// const BASE_URL = "http://184.168.125.186:5000/api/web/";
// const IMG_URL = "http://184.168.125.186:5000/api/uploads/images/";
// const LEAD_URL = "http://184.168.125.186:5000/api/web/";
// const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// // const DEFAULT_KEY ='903'
// const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
// const BASE = "http://184.168.125.186:5000/";

import companyName from "../store/mobxStore/companyName";
const COMPANY_NAME = companyName.companyName ?? "Company Name";
const BASE_URL = "https://admin.ntintl.com/api/web/";
const IMG_URL = "https://admin.ntintl.com/api/uploads/images/";
const LEAD_URL = "https://admin.ntintl.com/api/web/";
const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// const DEFAULT_KEY ='903'
const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
const BASE = "https://admin.ntintl.com/";

export {
  COMPANY_NAME,
  BASE_URL,
  IMG_URL,
  API_KEY,
  BASE,
  DEFAULT_KEY,
  LEAD_URL,
};
