import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PageTitle from "../common/pageTitle";
import InvoiceContent from "./InvoiceContent";
import invoiceService from "../../../service/shopping/invoice";
import companyName from "../../../store/mobxStore/companyName";

function ModalForm(props) {
  let service = new invoiceService();

  const [state, setState] = useState({
    alert: {
      show: false,
      message: "",
      variant: "",
    },
  });
  const buttonGroups = [];
  useEffect(() => {
    const id = props.encId;

    service.getInvoice(id, true).then((res) => {
      if (res.status) {
        if (state) {
          setState((prevState) => ({
            ...prevState,
            alert: {
              show: false,
              message: "validation.purchaseCompleatedSuccessfully",
              variant: "success",
              field: {
                number: res.data.invoice_no,
              },
            },
          }));
        }
        const productDetials = res.data.product_details.map((list) => {
          console.log(list, "************************************");
          return {
            name: list.product_name,
            qty: list.quantity,
            price: list.product_amount,
            subtotal: list.amount,
          };
        });
        setState((prevState) => ({
          ...prevState,
          ...res.data,
          product_details: productDetials,
        }));
      } else {
        if (res.error.code === 1002) {
          props.history.push("/logout");
        } else if (res.error.code === 1057) {
          props.history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        } else {
          props.history.push("/repurchaseReport");
        }
      }
    });
  }, []);
  const { t } = props;
  return (
    <div className="h-100">
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.invoice")}
        </title>
      </Helmet>
      <PageTitle title="invoice" buttonOn={false} group={buttonGroups} />
      <InvoiceContent {...state} fromReport={true} />
    </div>
  );
}
export default withTranslation()(withRouter(ModalForm));
