import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import ModalForm from "./ModalForm";
import { useSelector } from "react-redux";
import "react-data-table-component-extensions/dist/index.css";
import { CurrencyDecimalFormat } from "../../helper";
import style from "./invoice.module.scss";
import { Modal, Button } from "react-bootstrap";
function ReportTable(props) {
  const { t } = useTranslation();
  const Currency = useSelector((state) => state.curr);
  const [state, setState] = useState({
    modal: {
      status: false,
      encId: "",
      invoiceNo: "",
    },
  });
  const columns = [
    {
      name: t("Common.invoice"),
      selector: (row) => row.invoice_no,
      cell: (row) => (
        <a
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => {
            modalOpen(row.encryptId, row.invoice_no);
          }}
        >
          {row.invoice_no}
        </a>
      ),
      //   width : "30%"
    },
    {
      name: t("Common.totalAmount"),
      selector: (row) => row.amount,
      cell: (row) => (
        <span className={`${style.primaryBadge} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {CurrencyDecimalFormat(
            row.amount * Currency.value,
            Currency.precision
          )}
        </span>
      ),
    },
    {
      name: t("Common.paymentMethod"),
      selector: (row) => row.payment_method,
      cell: (row) => <span>{t(`Common.${row.payment_method}`)}</span>,
    },
    {
      name: t("Common.purchaseDate"),
      selector: (row) => row.purchase_date,
    },
  ];

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      modal: {
        status: false,
        encId: "",
        invoiceNo: "",
      },
    }));
  };
  const modalOpen = (encId, invoiceNo) => {
    setState((prevState) => ({
      ...prevState,
      modal: {
        status: true,
        encId: encId,
        invoiceNo: invoiceNo,
      },
    }));
  };
  const handlePerRowsChange = (perPage, inPage) => {
    props.paginationchange(inPage, perPage);
  };
  const handlePageChange = (inPage) => {
    props.paginationchange(inPage, props.perPage);
  };
  return (
    <>
      <div>
        <DataTable
          columns={columns}
          data={props.data}
          highlightOnHover
          progressPending={props.pending}
          progressComponent={<div>Loading</div>}
          persistTableHead
          noHeader
          pagination
          paginationServer
          responsive
          paginationTotalRows={props.totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          noDataComponent={t("Common.noDataRecordsToDisplay")}
          // onSort={handleOnSort}
        />
      </div>
      <div>
        <Modal
          show={state.modal.status}
          onHide={handleClose}
          dialogClassName={`${
            style.ModalDialog
          } FundTransferModal animate__animated ${
            state.modal.status
              ? "animate__fadeInRight"
              : "animate__fadeOutRight"
          }  `}
        >
          <Modal.Header closeButton bsPrefix={style.ModalHeader}>
            {/* <Modal.Title>INVOICE: #{state.modal.invoiceNo}</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className={style.ModalBodyHeader}>
              <ModalForm encId={state.modal.encId} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default ReportTable;
