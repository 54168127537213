import React from "react";
import Tiles from "../../components/crm/Tiles";
import FollowUpMissedOrToday from "../../components/crm/FollowUpMissedOrToday";
import FollowUpRecentTable from "../../components/crm/FollowUpRecentTable";
import { AddonNotification } from "../common";
//css
import { Container, Row, Col } from "react-bootstrap";
// import style from './crm.module.scss';
import { useTranslation } from "react-i18next";
import CrmModal from "./CrmModal";
import AddForm from "./AddForm";
import AddFollowUp from "./AddFollowUp";
import NextFollowUp from "./NextFollowUp";
const Content = (props) => {
  const { t } = useTranslation();
  return (
    <Container fluid className={`p-3`}>
      {/* <AddonNotification/> */}
      <Row>
        <Col md={12} lg={12} xl={12}>
          <Row>
            <Tiles
              count={props.tiles.total_ongoing_leads_count}
              title={t("Crm.totalOngoingLeads")}
              class_name="bg_primary"
              today={props.tiles.total_ongoing_leads_count}
              today_title={t("Crm.todayLeads")}
              filter="Ongoing"
            />
            <Tiles
              count={props.tiles.total_accepted_leads_count}
              title={t("Crm.totalAcceptedLeads")}
              class_name="bg-info"
              today=""
              filter="Accepted"
              today_title=""
            />
            <Tiles
              count={props.tiles.total_rejected_leads_count}
              title={t("Crm.totalRejectedLeads")}
              class_name="bg_primary"
              today=""
              today_title=""
              filter="Rejected"
            />
          </Row>
        </Col>
      </Row>
      {props.followup && (
        <React.Fragment>
          <FollowUpMissedOrToday
            followupdata={props.followup.followupstoday}
            type="followupstoday"
            title={t("Crm.yourFollowupforToday")}
            showModal={props.showModal}
          />
          <FollowUpMissedOrToday
            followupdata={props.followup.followupsmissed}
            title={t("Crm.yourMissedFollowup")}
            showModal={props.showModal}
            type="followupsmissed"
          />
          <FollowUpRecentTable
            followupdata={props.followup.followuprecent}
            title={t("Crm.recentLeads")}
            showModal={props.showModal}
          />
        </React.Fragment>
      )}
      <CrmModal
        show={props.modal.show}
        handleClose={props.handleClose}
        title={
          props.modal.title ? t(`Crm.${props.modal.title}`) : t("Crm.editLead")
        }
        // title={t(`Crm.${props.modal.title}`)}
      >
        {props.modal.title === "edit" && (
          <AddForm
            data={props.modal.data}
            id={props.modal.id}
            name={props.modal.data.first_name}
            BtnName="updateLead"
            isUpdate={true}
            submitCallback={props.callback}
            followup={props.followup}
          />
        )}
        {props.modal.title === "addFollowup" && (
          <AddFollowUp
            id={props.modal.id}
            name={props.modal.data.first_name}
            callBack={props.callback}
          />
        )}

        {props.modal.title === "NextFollowup" && (
          <NextFollowUp
            id={props.modal.id}
            name={props.modal.data.first_name}
            callBack={props.callback}
            data={props.modal.data}
            callback={props.callback}
          />
        )}
      </CrmModal>
    </Container>
  );
};

export default Content;
