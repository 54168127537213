import React from "react";
import style from "./invoice.module.scss";
import { Card, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Summary } from "./";
import moment from "moment";
function PrintContent(props) {
  const { t } = useTranslation();
  return (
    <div id="print_area">
      <Card className={style.panel}>
        <Card.Body className={style.panelBody}>
          <div className={style.logoImg}>
            {props.companyInfo && (
              <img src={props.companyInfo.login_logo} alt="" />
            )}
          </div>
          <Row>
            <Col xs={6}>
              <h4>{props.companyInfo && props.companyInfo.company_name}</h4>
              <p>{props.companyInfo && props.companyInfo.company_address}</p>
              <p>
                {props.companyInfo && <span>{t("Common.phone")} : </span>}{" "}
                <span>{props.companyInfo && props.companyInfo.phone}</span>
                <br />
                {props.companyInfo && <span>{t("profile.email")} : </span>}{" "}
                <span>{props.companyInfo && props.companyInfo.email}</span>
              </p>
            </Col>
            <Col xs={6} className={`text-right`}>
              <p>#{props.invoice_no && props.invoice_no}</p>
              <h5>
                {props.order_date &&
                  moment(props.order_date).format("D MMM, YYYY")}
              </h5>
            </Col>
          </Row>
          {/* <h4></h4> */}
          {props.companyInfo && (
            <Row>
              <Col sm={6}>
                <h4>{t("Common.company")}</h4>
                <div className={`${style.well}`}>
                  <address>
                    <strong>
                      {props.companyInfo && props.companyInfo.company_name}
                    </strong>
                    <br />
                    <span>
                      {props.companyInfo && props.companyInfo.company_address}
                    </span>
                    <br />
                    <abbr title="Phone">{t("Common.phone")}:</abbr>
                    {props.companyInfo && props.companyInfo.phone}
                  </address>
                  <address>
                    <strong>{t("profile.email")}</strong>
                    <br />
                    <span>{props.companyInfo && props.companyInfo.email}</span>
                  </address>
                </div>
              </Col>
              <Col sm={6}>
                <h4>{t("Common.purchaseAddress")} :</h4>
                <div className={`${style.well}`}>
                  <address>
                    <strong>{props.address && props.address.name}</strong>
                    <br />
                    <span>
                      {props.address &&
                        `
                                                        ${
                                                          props.address.name &&
                                                          props.address.name
                                                        } 
                                                        ${
                                                          props.address.pin &&
                                                          props.address.pin
                                                        } 
                                                        ${
                                                          props.address.town &&
                                                          props.address.town
                                                        }`}
                    </span>
                    <br />
                    <abbr title="Phone">{t("Common.phone")}:</abbr>
                    {props.address && props.address.mobile}
                  </address>
                  <address style={{ visibility: "hidden" }}>
                    <strong>Emial</strong>
                    <br />
                    <span>NA</span>
                  </address>
                </div>
              </Col>
            </Row>
          )}
          {props.paymentMethod && (
            <>
              <Row>
                <Col sm={6}>
                  <h4>{t("Common.paymentMethod")} :</h4>
                  <div
                    className={`${style.well}`}
                    style={props.fromReport && { fontSize: "20px" }}
                  >
                    <address>
                      <abbr title="Payment method">
                        {t("Common.paymentMethod")}:
                      </abbr>
                      {`    ` + props.paymentMethod}

                      {props.splitFromEwallet && (
                        <>
                          <br />
                          <abbr title="Deducted from Ewallet for the purchase">
                            {t("Common.splitFromEwallet")}:
                          </abbr>
                          {`    ` + props.splitFromEwallet}
                          <br />
                          <abbr title="Deducted from E-point wallet for the purchase">
                            {t("Common.splitFromPwallet")}:
                          </abbr>
                          {`    ` + props.splitFromPwallet}
                        </>
                      )}
                    </address>
                  </div>
                </Col>
                {/* <Col sm={6}>{t("Common.paymentMethod")}:</Col>
                <Col sm={6}>props.paymentMethod</Col> */}
              </Row>
            </>
          )}

          {props.product_details && <Summary data={props.product_details} />}
        </Card.Body>
      </Card>
    </div>
  );
}

export default PrintContent;
