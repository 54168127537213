import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//service
import { CurrencyDecimalFormat } from "../../helper";
import style from "./ewallet.module.scss";
import moment from "moment";

import { Filter } from ".";
import { ContactsOutlined } from "@mui/icons-material";
const HistoryTable = React.memo(({ ContentData, paginationchange, loader }) => {
  const { t } = useTranslation();
  const Currency = useSelector((state) => state.curr);
  const [state, setState] = useState({ data: [] });

  const [date, setDate] = useState({
    selectedValues: [],
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    //label : moment().startOf('month').format('MMMM D, YYYY') + ' - ' + moment().format('MMMM D, YYYY')
  });
  const columns = [
    {
      name: t("Common.description").toUpperCase(),
      selector: (row) => row.description,
      width: "30%",
    },
    {
      name: t("Common.amount").toUpperCase(),
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => (
        <span className={`${row.classnameamount} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {(row.amount * Currency.value).toFixed(Currency.precision)}
        </span>
      ),
    },
    {
      name: t("Common.transactionFee").toUpperCase(),
      selector: (row) => row.transaction_fee,
      sortable: true,
      cell: (row) => (
        <span className={`${style.balanceAmount} ${style.badge}`}>
          {" "}
          {row.transaction_fee === "NA"
            ? row.transaction_fee
            : `${Currency.currentCurr} ${(
                row.transaction_fee * Currency.value
              ).toFixed(Currency.precision)}`}{" "}
        </span>
      ),
    },
    {
      name: t("Common.transactionDate").toUpperCase(),
      selector: (row) => row.date,
      sortable: true,
    },
  ];

  const { data } = state;
  useEffect(() => {
    let NewData = ContentData.data.map((resData) => {
      if (resData.type === "credit") {
        return {
          classnameamount: style.credit,
          amount: resData.amount,
          transaction_fee: resData.transaction_fee,
          description: t("ewallet." + resData.description1.langCode, {
            username: resData.description1?.user,
          }),
          date: resData.date,
        };
      } else if (resData.type === "debit") {
        return {
          classnameamount: style.debit,
          amount: resData.amount,
          transaction_fee: resData.transaction_fee,
          description: t("ewallet." + resData.description1.langCode, {
            username: resData.description1?.user,
          }),
          date: resData.date,
        };
      }
      return null;
    });
    setState((prev) => ({
      ...prev,
      data: NewData,
    }));
  }, [ContentData]);

  // onchange page
  const handlePageChange = (inPage) => {
    paginationchange(
      inPage,
      ContentData.perPage,
      "",
      "",
      ContentData.direction,
      "",
      date.selectedValues
    );
  };

  // handlePerRowsChange
  const handlePerRowsChange = (perPage, inPage) => {
    paginationchange(
      ContentData.inPage,
      ContentData.perPage,
      date?.start,
      date?.end,

      "",
      "",
      date.selectedValues
    );
  };

  const handleOnSort = ({ selector }, sortDirection) => {
    // paginationchange(
    //   ContentData.inPage,
    //   ContentData.perPage,
    //   date?.start,
    //   date?.end,
    //   sortDirection,
    //   selector,
    //   date.selectedValues
    // );
  };

  const filterType = [
    { key: t("Common.debit"), value: "user_debit" },
    { key: t("Common.credit"), value: "user_credit" },
  ];

  //reset handler
  const resetData = (start, end, keys = "") => {
    loader.EarningsTable.reset = true;

    paginationchange(
      ContentData.inPage,
      ContentData.perPage,
      moment().startOf("month").format("YYYY-MM-DD"),
      "",
      "",
      ""
    );

    setDate((prev) => ({
      ...prev,
      start: start,
      end: end,
      selectedValues: [],
    }));
  };

  //finter handler
  const filterHandler = (start, end, keys = "") => {
    loader.EarningsTable.search = true;

    paginationchange(
      ContentData.inPage,
      ContentData.perPage,
      start,
      end,
      "",
      "",
      keys[0] ? keys[0].value : ""
    );
    setDate((prev) => ({
      ...prev,
      start: start,
      end: end,
      selectedValues: keys[0] ? keys[0].value : "",
    }));
  };
  return (
    <div>
      <Filter
        filterHandler={filterHandler}
        type={filterType}
        resetHandler={resetData}
        isSingle={true}
        loader={loader}
      />
      <DataTable
        columns={columns}
        data={data}
        highlightOnHover
        progressPending={ContentData.pending}
        progressComponent={<div>Loading</div>}
        persistTableHead
        noHeader
        pagination
        paginationServer
        responsive
        paginationTotalRows={ContentData.totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        noDataComponent={t("Common.noDataRecordsToDisplay")}
        //onSort={handleOnSort}
      />
    </div>
  );
});

export default HistoryTable;
