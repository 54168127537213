import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";
import axios from "axios";
import API from "../../api/api";

export default class EwalletService {
  key = localStorage.getItem("apiKey");

  Ewallet = async () => {
    return await API.API.get("pwallet/pointWallet_tile")
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };

  statement = async (start = 0, length = 10) => {
    return await API.API.get(
      "pwallet/pointWallet_statement_table?length=" +
        length +
        "&start=" +
        start +
        ""
    )
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
  history = async (
    start = 0,
    length = 10,
    direction = "",
    startDate = "",
    endDate = "",
    keys = "",
    order = ""
  ) => {
    return await API.API.get(
      "pwallet/pointWallet_history_table?direction=" +
        direction +
        "&length=" +
        length +
        "&start=" +
        start +
        "&start_date=" +
        startDate +
        "&end_date=" +
        endDate +
        "&order=" +
        keys +
        "&type=" +
        order
    )
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };

  wallet = async (start, length) => {
    return await API.API.get(
      "pwallet/purchase_wallet_table?length=" + length + "&start=" + start + ""
    )
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
  earnigs = async (
    start = 0,
    length = 10,
    direction = "",
    startDate = "",
    endDate = "",
    keys = "",
    order = ""
  ) => {
    return await API.API.get(
      "pwallet/user_earnings_table?direction=" +
        direction +
        "&length=" +
        length +
        "&start=" +
        start +
        "&start_date=" +
        startDate +
        "&end_date=" +
        endDate +
        "&order=" +
        order
    )
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
  fundTransfer = async (payload) => {
    return await API.API.post("pwallet/fund_transfer", payload)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
  exportData = async (startDate = "", endDate = "", keys = "", type = "") => {
    return await API.API.get(
      "pwallet/earnings_export_data?type=" +
        type +
        "&start_date=" +
        startDate +
        "&end_date=" +
        endDate +
        "&" +
        keys
    )
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };

  // async Ewallet(){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'pwallet/ewallet_tile', {
  //             method: 'GET',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }
  // async statement(start=0,length=10){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'pwallet/ewallet_statement_table?length='+length+'&start='+start+'', {
  //             method: 'GET',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }
  // async history(start=0,length=10,direction="",startDate="",endDate="",keys="",order=""){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'pwallet/ewallet_history_table?direction='+direction+'&length='+length+'&start='+start+'&start_date='+startDate+'&end_date='+endDate+'&order='+keys+'&type='+order, {
  //             method: 'GET',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }
  // async wallet(start,length){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'pwallet/purchase_wallet_table?length='+length+'&start='+start+'', {
  //             method: 'GET',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }

  // async earnigs(start=0,length=10,direction="",startDate="",endDate="",keys="",order=""){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'pwallet/user_earnings_table?direction='+direction+'&length='+length+'&start='+start+'&start_date='+startDate+'&end_date='+endDate+'&order='+keys+'&'+order, {
  //             method: 'GET',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }

  //fund Transfer post
  // async fundTransfer(data){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'pwallet/fund_transfer', {
  //             method: 'POST',
  //             headers : {
  //                 // 'Accept'        : 'application/json',
  //                 // 'Content-Type'  : 'application/json',
  //                 'Accept-Language': '',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             body: data
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }

  async exportData(startDate = "", endDate = "", keys = "", type = "") {
    const response = await fetch(
      BASE_URL +
        "pwallet/earnings_export_data?type=" +
        type +
        "&start_date=" +
        startDate +
        "&end_date=" +
        endDate +
        "&" +
        keys,
      {
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    return response.json();
  }
}
